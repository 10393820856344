import request from '@/utils/request'
// 设备列表
export function getDeviceList(data) {
  return request('post', '/apm/device/list', data);
}

// 位置列表（服务+房间）
export function getRoomList(data) {
  return request('post', '/apm/device/getRoomList', data);
}

// 设备类型
export function getDeviceType() {
  return request('get', '/apm/device/getDeviceTypeList');
}

// 服务商列表
export function getSpList() {
  return request('get', '/apm/device/getDeviceServiceTypeList');
}

// 网关列表
export function getWayList(data) {
  return request('get', '/apm/gateway/getListByShopId?shopId=' + data.shopId);
}

// 新增设备
export function getDeviceAdd(data) {
  return request('post', '/apm/device/create', data);
}

// 编辑设备
export function getDeviceEdit(data) {
  return request('post', '/apm/device/update', data);
}

// 拆除设备
export function getDeviceDel(data) {
  return request('post', '/apm/device/del', data);
}

// 批量更换设备
export function getChangeWay(data) {
  return request('post', '/apm/device/replacement/gateway', data);
}

// 网关列表
export function getAllWayList(data) {
  return request('post', '/apm/gateway/list', data);
}

// 新增网关
export function getWayAdd(data) {
  return request('post', '/apm/gateway/create', data);
}

// 编辑网关
export function getWayEdit(data) {
  return request('post', '/apm/gateway/update', data);
}

// 拆除网关
export function getWayDel(data) {
  return request('post', '/apm/gateway/del', data);
}

// 挂载列表
export function getWayMountList(data) {
  return request('post', '/apm/device/listByGatewayId', data);
}

// 绑定
export function getBind(data) {
  return request('post', '/apm/device/bind', data);
}

// 解绑
export function getRemove(data) {
  return request('post', '/apm/device/unbind', data);
}

// 设备型号列表（分页）
export function getUnitTypeList(data) {
  return request('post', '/apm/device/model/page', data);
}

// 新增设备型号
export function getUnitTypeAdd(data) {
  return request('post', '/apm/device/model/create', data);
}

// 获取货格设置详情
export function getCellList(data) {
  return request('post', '/apm/device/model/goods/shelves/list', data);
}

// 新增货格
export function getCellAdd(data) {
  return request('post', '/apm/device/model/add/goods/shelves', data);
}

// 设备型号列表（不分页）
export function getDeviceModelList(data) {
  return request('post', '/apm/device/model/list', data);
}