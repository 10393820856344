<template>
  <div class="cell-set">
    <el-button type="text" size="medium" @click.stop="onCell()">货格管理</el-button>
    <!-- 货格设置弹框 -->
    <el-dialog title="货格设置" :visible.sync="infoShow" :close-on-click-modal="false" append-to-body width="45%">
      <section class="table-search">
        <div class="search-L">
        </div>
        <div class="search-R">
          <el-button type="primary" @click="onAdd()">新增货格</el-button>
        </div>
      </section>
      <ul class="cells" v-if="cellList.length > 0">
        <li class="cell-item" v-for="item in cellList">
          <p class="cell-p">
            <span>{{ item.goodsShelvesName }}</span>
            <span>容量：{{ item.goodsShelvesCapacity }}</span>
          </p>
          <p class="cell-p">
            <span>控制编号</span>
            <span>{{ item.goodsShelvesNumber }}</span>
          </p>
        </li>
      </ul>
      <el-empty v-else description="暂无货格"></el-empty>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="25%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="货格名称" prop="goodsShelvesName">
            <el-input v-model.trim="params.goodsShelvesName" autocomplete="off" maxlength="5" placeholder="请填写货格名称"
              clearable></el-input>
          </el-form-item>
          <el-form-item label="控制编号" prop="goodsShelvesNumber">
            <el-input v-model.trim="params.goodsShelvesNumber" autocomplete="off" maxlength="2" placeholder="请填写控制编号"
              oninput="value=value.replace(/[^\d]/g,'')" clearable></el-input>
          </el-form-item>
          <el-form-item label="货格容量" prop="goodsShelvesCapacity">
            <el-input v-model.trim="params.goodsShelvesCapacity" autocomplete="off" maxlength="3" placeholder="请填写货格容量"
              oninput="value=value.replace(/[^\d]/g,'')" clearable></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
        </section>
        <section class="form-footer">
          <p class="tips">tips: 确定后立即应用到同型号设备，请谨慎填写</p>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCellList,
  getCellAdd,
} from "@/api/device/device";
import { rules } from "@/db/rules";
export default {
  props: ['row'],
  data() {
    return {
      // 编辑弹框
      infoShow: false, //弹框开关
      cellList: "",//货格详情
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        goodsShelvesName: "",
        goodsShelvesNumber: "",
        goodsShelvesCapacity: "",
      },
    }
  },
  methods: {
    // 【请求】详情
    getCellList() {
      let data = this.row;
      getCellList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.cellList = res.data;
          }
        }
      });
    },

    // 【请求】新增
    getCellAdd() {
      let data = this.params;
      getCellAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getCellList();// 【请求】表格数据
        }
      });
    },

    // 【监听】货格管理
    onCell() {
      this.infoShow = true;
      this.getCellList();
    },

    // 【监听】新增货格
    onAdd() {
      console.log('this.row: ', this.row);
      this.editShow = true;
      this.editTitle = "新增货格";
      this.params = {
        deviceModel: this.row.deviceModel,
        deviceType: this.row.deviceType,
        deviceServiceType: this.row.deviceServiceType,
        goodsShelvesName: "",
        goodsShelvesNumber: "",
        goodsShelvesCapacity: "",
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCellAdd();
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.cell-set {
  display: inline-flex;
  margin-right: .1rem;
}

.cells {
  display: flex;
  flex-wrap: wrap;
}

.cell-item {
  width: 1.5rem;
  margin: 0 .15rem .15rem 0;
  padding: .15rem;
  border: 1px solid #DDDDDD;
}


.cell-p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .15rem 0;
}

.table-search {
  margin-bottom: .15rem;
  display: flex;
  justify-content: space-between;
}

.form-footer {
  display: flex;
  justify-content: center;
}

.tips {
  margin-top: .15rem;
  color: red;
}
</style>