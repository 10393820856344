<template>
  <div class="unit-type">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.deviceType" placeholder="请选择设备类型" @change="reload" clearable class="mr15">
          <el-option v-for="item in deviceTypeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增型号</el-button>
      </div>
    </section>
    <!-- Table表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="设备类型" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备型号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceModel || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务商" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceServiceTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <CellSet :row="scope.row" v-if="scope.row.deviceType == 13"></CellSet>
        </template>
      </el-table-column>
    </el-table>
    <!-- Table分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="设备类型" prop="deviceType">
            <el-select v-model="params.deviceType" placeholder="请选择设备类型" clearable>
              <el-option v-for="item in deviceTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备服务商" prop="deviceServiceType">
            <el-select v-model="params.deviceServiceType" placeholder="请选择设备服务商">
              <el-option v-for="item in spList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" prop="deviceModel">
            <el-input v-model.trim="params.deviceModel" autocomplete="off" maxlength="16" placeholder="请填写设备型号"
              clearable></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUnitTypeList,
  getDeviceType,
  getUnitTypeAdd,
  getSpList,
} from "@/api/device/device";
import { rules } from "@/db/rules";
import CellSet from "@/views/device/child/CellSet.vue"
export default {
  components: { CellSet },
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        deviceType: "",//设备类型
      },
      deviceTypeList: [],//设备类型
      spList: [],//服务商列表
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        deviceType: "",
        deviceServiceType: "",
        deviceModel: "",
      },
    }
  },
  mounted() {
    this.getUnitTypeList();// 【请求】表格数据
    this.getDeviceType();// 【请求】设备类型
    this.getSpList();//【请求】服务商列表
  },
  methods: {
    // 【请求】表格数据
    getUnitTypeList() {
      let data = this.tableParams;
      getUnitTypeList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】设备类型
    getDeviceType() {
      getDeviceType().then(res => {
        if (res.isSuccess == "yes") {
          this.deviceTypeList = res.data;
        }
      })
    },

    // 【请求】服务商列表
    getSpList() {
      getSpList().then(res => {
        if (res.isSuccess == "yes") {
          this.spList = res.data;
        }
      })
    },

    // 【请求】新增
    getUnitTypeAdd() {
      let data = this.params;
      getUnitTypeAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },


    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUnitTypeList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getUnitTypeList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】添加型号
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增设备型号"
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getUnitTypeAdd();
        } else {
          return false;
        }
      });
    },

  }
}

</script>


<style lang="scss" scoped>
.unit-type {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>